import './App.css';
// import ChatScreen from './Components/ChatScreen';
import ComingSoon from './Components/ComingSoon';

function App() {
  return (
    <div className="App" style={{}}>
      <ComingSoon/>
        {/* <ChatScreen/> */}
    </div>
  );
}

export default App;
