import React, { useEffect, useState } from "react";
import { Box, Typography, Button } from "@mui/material";
import ModelDrawer from "./ModelDrawer";
import ConfigurationModal from "./ConfigurationModal";
import { useNavigate } from "react-router-dom";

const ModelConfigurator = ({ configurations, setConfigurations, models }) => {
  const [selectedModel, setSelectedModel] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  // Save configurations to localStorage
  const saveConfigurationsToLocalStorage = (config) => {
    localStorage.setItem("modelConfigurations", JSON.stringify(config));
  };

  // Load configurations from localStorage on mount
  useEffect(() => {
    const savedConfigurations = localStorage.getItem("modelConfigurations");
    if (savedConfigurations) {
      setConfigurations(JSON.parse(savedConfigurations));
    } 
  
  }, [models, setConfigurations]);

  const handleConfigure = (modelName) => {
    setSelectedModel(modelName);
    setIsModalOpen(true);
  };

  const handleSaveConfig = (modelName, config) => {
    const updatedConfigurations = {
      ...configurations,
      [modelName]: config,
    };
    setConfigurations(updatedConfigurations);
    saveConfigurationsToLocalStorage(updatedConfigurations);
  };

  return (
    <div style={{ display: "flex" }}>
      <ModelDrawer models={models} onConfigure={handleConfigure} />

      <Button
        variant="contained"
        color="primary"
        sx={{ mt: 4 }}
        onClick={() => navigate(-1)}
      >
        Back to Chat
      </Button>

      <ConfigurationModal
        open={isModalOpen}
        modelName={selectedModel}
        config={
          configurations[selectedModel] || {
            tone: 50,
            imitate: "Normal Person",
            formality: 50,
          }
        }
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveConfig}
      />
    </div>
  );
};

export default ModelConfigurator;
