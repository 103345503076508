import React, { useEffect, useState } from "react";
import {
  Drawer,
  List,
  ListItem,
  ListItemText,
  IconButton,
  Divider,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import SettingsIcon from "@mui/icons-material/Settings";
import ListAltIcon from "@mui/icons-material/ListAlt";
import TuneIcon from "@mui/icons-material/Tune";
import WorkspacePremiumIcon from "@mui/icons-material/WorkspacePremium";
import { motion } from "framer-motion";
import { useAuth } from "../AuthContext";

const ModelDrawer = ({ models, onConfigure, configurations }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const { user } = useAuth();

  const toggleDrawer = () => {
    setMobileOpen(!mobileOpen);
  };

  useEffect(() => {
    console.log("Configurations changed", configurations);
  }, [configurations]);

  const drawerContent = (
    <>
      {/* Header */}
      <Typography
        variant="h5"
        component="div"
        sx={{
          m: 3,
          textAlign: "center",
          color: "#FFFFFF",
          fontWeight: "bold",
          textTransform: "uppercase",
          letterSpacing: "0.1em",
          textShadow: "2px 2px 4px rgba(0,0,0,0.5)",
        }}
      >
        AI Models
      </Typography>
      <Divider sx={{ bgcolor: "#4CAF50", mx: 3 }} />

      {/* Model List */}
      <List>
        {models.map((model) => (
          <motion.div
            key={model.name}
            whileHover={{
              scale: 1.03,
              boxShadow: "0px 10px 20px rgba(0,0,0,0.3)",
            }}
            style={{ marginBottom: "10px" }}
          >
            <ListItem
              button
              sx={{
                display: "flex",
                alignItems: "center",
                p: 2,
                borderRadius: 2,
                transition: "all 0.3s ease",
                bgcolor: model.premium ? "#FFD700" : "#3949AB",
                color: model.premium ? "black" : "white",
                "&:hover": {
                  bgcolor: model.premium ? "#FFC107" : "#283593",
                },
              }}
            >
              {/* Model Icon */}
              <ListAltIcon
                sx={{
                  color: model.premium ? "black" : "#C5CAE9",
                  fontSize: 30,
                  mr: 2,
                }}
              />

              {/* Model Details */}
              <ListItemText
                primary={model.description}
                secondary={
                  configurations[model.name] ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 1,
                        mt: 1,
                      }}
                    >
                      {Object.entries(configurations[model.name]).map(([key, value]) => (
                        <Box
                          key={key}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            bgcolor: "#F5F5F5",
                            p: 1,
                            borderRadius: "8px",
                            boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
                          }}
                        >
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: "bold",
                              color: "#3949AB",
                              textTransform: "capitalize",
                            }}
                          >
                            {key}:
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{
                              color: "#555",
                              fontStyle: key === "imitate" ? "italic" : "normal",
                            }}
                          >
                            {value}
                          </Typography>
                        </Box>
                      ))}
                    </Box>
                  ) : (
                    <Typography
                      variant="body2"
                      sx={{
                        color: "#C5CAE9",
                        fontStyle: "italic",
                      }}
                    >
                      Not Configured
                    </Typography>
                  )
                }
                primaryTypographyProps={{
                  fontWeight: "bold",
                  fontSize: "1rem",
                }}
              />

              {/* Premium Badge */}
              {model.premium && (
                <Tooltip title="Premium Model">
                  <WorkspacePremiumIcon
                    sx={{
                      color: "#FFB300",
                      ml: 1,
                      animation: "pulse 1.5s infinite alternate",
                    }}
                  />
                </Tooltip>
              )}

              {/* Configure Button */}
              <Tooltip
                title={
                  user.premium || !model.premium
                    ? "Configure Model"
                    : "Premium Users Only"
                }
              >
                <IconButton
                  onClick={() =>
                    user.premium || !model.premium
                      ? onConfigure(model.name)
                      : alert("Only for premium users. Feature coming soon!")
                  }
                  sx={{
                    color: model.premium ? "black" : "#C5CAE9",
                  }}
                >
                  <SettingsIcon />
                </IconButton>
              </Tooltip>
            </ListItem>
          </motion.div>
        ))}
      </List>
    </>
  );

  return (
    <>
      {/* Mobile View */}
      <Box sx={{ display: { xs: "block", md: "none" } }}>
        <IconButton color="inherit" onClick={toggleDrawer} sx={{ ml: 2 }}>
          <TuneIcon />
          <Typography
            variant="body1"
            sx={{
              ml: 1,
              border: "1px solid #C5CAE9",
              borderRadius: 10,
              px: 2,
              bgcolor: "#E8EAF6",
              color: "#3F51B5",
              fontWeight: "bold",
              "&:hover": { bgcolor: "#D3D3D3" },
            }}
          >
            Configure Models
          </Typography>
        </IconButton>
        <Drawer
          anchor="left"
          open={mobileOpen}
          onClose={toggleDrawer}
          sx={{
            "& .MuiDrawer-paper": {
              width: 300,
              boxSizing: "border-box",
              bgcolor: "#283593",
              color: "#E8EAF6",
            },
          }}
        >
          {drawerContent}
        </Drawer>
      </Box>

      {/* Desktop View */}
      <Drawer
        variant="permanent"
        anchor="left"
        sx={{
          display: { xs: "none", md: "block" },
          width: 300,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: 300,
            boxSizing: "border-box",
            bgcolor: "#283593",
            color: "#E8EAF6",
          },
        }}
      >
        {drawerContent}
      </Drawer>
    </>
  );
};

export default ModelDrawer;
