import React, { useState, useEffect } from "react";
import { decryptData } from "../utils/Security";
import { useAuth } from "../AuthContext";
import { getChatHistory } from "../api/api";
import { v4 as uuidv4 } from "uuid";
import HeaderActions from "./HeaderActions";
import { Box, Typography, Paper, List, ListItem, Divider, Button } from "@mui/material";

const ChatHistory = () => {
  const [topics, setTopics] = useState([]);
  const [selectedTopic, setSelectedTopic] = useState(null);
  const [chatHistory, setChatHistory] = useState([]);
  const { user } = useAuth();

  const getChatLocalStorageItems = () => {
    const chatItems = Object.keys(localStorage)
      .filter((key) => key.startsWith("aiOske5fS_2361c"))
      .reduce((acc, key) => {
        acc[key] = localStorage.getItem(key);
        return acc;
      }, {});

    let topics = [];
    Object.values(chatItems).forEach((value) => {
      const decData = JSON.parse(decryptData(value));
      const extractedTopics = Object.keys(decData).map((key) => ({
        key: {
          topic: key.split("##")[0],
          timestamp: key.split("##")[1],
          fullKey: key,
        },
        val: decData[key],
      }));
      topics.push(...extractedTopics);
    });

    setTopics(topics);
    return topics.length > 0;
  };

  const getUUID = () => uuidv4().toString();

  useEffect(() => {
    if (!getChatLocalStorageItems() && user?.premium) {
      getChatHistory().then((data) => {
        if (data?.length > 0) {
          data.forEach((dt) => {
            localStorage.setItem("aiOske5fS_2361c" + getUUID(), dt.content);
          });
          getChatLocalStorageItems();
        }
      });
    }
  }, [user]);

  const handleTopicClick = (topic, val) => {
    setSelectedTopic(topic);
    setChatHistory(val);
  };

  return (
    <Box sx={{ padding: "20px", fontFamily: "Arial, sans-serif" }}>
      <HeaderActions  />
      <Typography
        variant="h4"
        sx={{
          textAlign: "center",
          color: "#4A90E2",
          mb: 4,
          fontWeight: "bold",
          textShadow: "2px 2px 4px rgba(0, 0, 0, 0.2)",
        }}
      >
        Chat History
      </Typography>

      <Box
        sx={{
          display: "flex",
          gap: 4,
          flexWrap: "wrap",
        }}
      >
        {/* Topics List */}
        <Paper
          elevation={6}
          sx={{
            flex: 1,
            padding: 3,
            borderRadius: "16px",
            minWidth: "300px",
            backgroundColor: "#F9FAFB",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h6"
            sx={{ color: "#333", mb: 2, fontWeight: "bold" }}
          >
            Topics
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <List sx={{ maxHeight: "500px", overflowY: "auto" }}>
            {topics.map(({ key: { topic, timestamp, fullKey }, val }) => (
              <ListItem
                key={fullKey}
                button
                onClick={() => handleTopicClick(topic, val)}
                sx={{
                  padding: 2,
                  mb: 1,
                  borderRadius: "12px",
                  backgroundColor:
                    selectedTopic === topic ? "#E0F7FA" : "#FFFFFF",
                  boxShadow:
                    selectedTopic === topic
                      ? "0 6px 12px rgba(0, 0, 0, 0.15)"
                      : "0 3px 6px rgba(0, 0, 0, 0.1)",
                  transition: "all 0.3s",
                  "&:hover": {
                    backgroundColor: "#E3F2FD",
                  },
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontWeight: "bold",
                      color: "#00796B",
                      fontSize: "1rem",
                    }}
                  >
                    {topic}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: "#555", fontSize: "0.9rem" }}
                  >
                    {new Date(timestamp).toLocaleString()}
                  </Typography>
                </Box>
              </ListItem>
            ))}
          </List>
        </Paper>

        {/* Chat Details */}
        <Paper
          elevation={6}
          sx={{
            flex: 2,
            padding: 3,
            borderRadius: "16px",
            backgroundColor: "#FFFFFF",
            boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          }}
        >
          <Typography
            variant="h6"
            sx={{
              color: "#333",
              borderBottom: "2px solid #4A90E2",
              paddingBottom: "5px",
              mb: 3,
              fontWeight: "bold",
            }}
          >
            Chats for: {selectedTopic || "Selected"} Topic
          </Typography>
          {selectedTopic ? (
            <Box sx={{ maxHeight: "500px", overflowY: "auto" }}>
              <List>
                {chatHistory.map((chat, index) => (
                  <ListItem
                    key={index}
                    sx={{
                      mb: 2,
                      padding: 2,
                      backgroundColor: "#F9FAFB",
                      borderRadius: "12px",
                      boxShadow: "0 2px 5px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <Box>
                      <Typography
                        variant="body2"
                        sx={{ fontWeight: "bold", mb: 1 }}
                      >
                        Model: {chat.model}
                      </Typography>
                      <Typography variant="body2" sx={{ color: "#555" }}>
                        Response: {chat.response}
                      </Typography>
                    </Box>
                  </ListItem>
                ))}
              </List>
            </Box>
          ) : (
            <Typography
              variant="body2"
              sx={{
                color: "#888",
                textAlign: "center",
                fontStyle: "italic",
              }}
            >
              Please select a topic to view the chats.
            </Typography>
          )}
        </Paper>
      </Box>
    </Box>
  );
};

export default ChatHistory;
