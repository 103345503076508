import CryptoJS from 'crypto-js';


// Function to encrypt data
export const encryptData = (data) => {
  try {
    const secretKey = process.env.REACT_APP_ENC_KEY;
   
    return CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
  } catch (error) {
    console.error("Encryption failed", error);
    return null;
  }
};

// Function to decrypt data
export const decryptData = (encryptedData) => {
  try {
    
    const secretKey = process.env.REACT_APP_ENC_KEY;
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    const decryptedData = bytes.toString(CryptoJS.enc.Utf8);
    return JSON.parse(decryptedData);
  } catch (error) {
    console.error("Decryption failed", error);
    return null;
  }
};