import React, { createContext, useState, useContext, useEffect } from "react";
import { loginUser } from "./api/api";
import { decryptData, encryptData } from "./utils/Security";

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false); // Authentication state
  const [user, setUser] = useState(null); // User information

  // Check and restore authentication state on app initialization
  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedUser = localStorage.getItem("ai9#fkLsR");
    if (storedToken && storedUser) {
      setIsAuthenticated(true);

      setUser((decryptData(storedUser))); // Restore user info
    }
  }, []);

  const login = async ({ email, password }) => {
    try {
      const userData = await loginUser(email, password);
      setIsAuthenticated(true);
      setUser(userData);

      // Save token and user info to localStorage
      localStorage.setItem("token", userData.token);
      localStorage.setItem("ai9#fkLsR", encryptData(userData));
    } catch (error) {
      console.error("Login failed:", error);
      throw error; // Propagate error for UI feedback
    }
  };

  const logout = () => {
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    // Clear other authentication-related data if necessary
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

// Custom hook to use AuthContext
export const useAuth = () => useContext(AuthContext);
