import React, { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import { styled } from '@mui/system';
import Countdown from 'react-countdown';

// Styled components for a clean design
const Container = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  backgroundColor: '#f0f0f0',
  padding: '2rem',
});

const Header = styled(Typography)({
  fontSize: '3rem',
  fontWeight: 'bold',
  color: '#333',
  marginBottom: '1rem',
  border: '2px solid #333',  // Add a solid border around the header
  padding: '10px',           // Add padding to prevent the text from touching the border
  borderRadius: '8px', 
  
  
});

const SubHeader = styled(Typography)({
  fontSize: '1.5rem',
  color: '#666',
  marginBottom: '2rem',
});

const FeatureBox = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  backgroundColor: '#fff',
  padding: '2rem',
  borderRadius: '8px',
  boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
  marginBottom: '2rem',
  textAlign: 'center',
});

const CountdownWrapper = styled(Box)({
  display: 'flex',
  justifyContent: 'center',
  marginBottom: '2rem',
});

const EmailSignup = styled(Box)({
  display: 'flex',
  gap: '1rem',
});

const features = [
    'Dynamic Multi-AI Conversations',
    'AI Experts with Unique Perspectives',
    'Topic-Driven Discussions',
    'Real-Time Insights and Reactions',
    'Interactive and Engaging Dialogue',
    '24/7 AI Availability for Ongoing Discussions',
  ];
// Countdown Renderer for Countdown Component
const renderer = ({ days, hours, minutes, seconds }) => {
  return (
    <Box>
      <Typography variant="h4" color="primary">
        {days}d {hours}h {minutes}m {seconds}s
      </Typography>
    </Box>
  );
};

const ComingSoon = () => {
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState(false);  // To track email validation

 

  const handleNotifyClick = () => {
    alert(`Thank you! We'll notify you at ${email}`);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  const handleEmailChange = (e) => {
    const emailValue = e.target.value;
    setEmail(emailValue);
    
    // Validate the email and set error state
    if (!validateEmail(emailValue)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  return (
    <Container>
        <Header>AI CHAT JAM</Header>
      <SubHeader> Coming Soon!</SubHeader>
      <SubHeader>Experience the Future of AI-Powered Conversations</SubHeader>

      <FeatureBox>
  <Typography variant="h5" color="secondary" gutterBottom>
    What You Can Expect:
  </Typography>
  {features.map((feature, index) => (
    <Box key={index} width="100%">  {/* Ensure full width and align left */}
      <Typography variant="body1" color="textSecondary" align="left">
        ➡️ {feature}
      </Typography>
    </Box>
  ))}
</FeatureBox>

<CountdownWrapper>
  <Countdown date={new Date('2024-11-30T00:00:00')} renderer={renderer} />
</CountdownWrapper>

      <EmailSignup>
      <TextField
  label="Enter your email"
  variant="outlined"
  value={email}
  type='email'
  onChange={handleEmailChange}
  fullWidth
  error={emailError}  // Display error if email is invalid
  helperText={emailError ? 'Invalid email address' : ''}  // Helper text for invalid email
/>
        <Button variant="contained" color="primary" onClick={handleNotifyClick}>
          Notify Me
        </Button>
      </EmailSignup>

      <Box mt={4}>
        <Typography variant="caption" color="textSecondary">
          *Get ready to be among the first to experience our AI chat!
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="caption" color="textSecondary">
          Product of  <a target='_blank' href='https://beatior.net'> Beatior Inc.</a>
        </Typography>
      </Box>
    </Container>
  );
};

export default ComingSoon;
