import React, { useState, useEffect } from 'react';
import { Container, Box } from '@mui/material';
import { motion } from 'framer-motion';
import axios from 'axios';
import ModelDrawer from './ModelDrawer';
import ConfigurationModal from './ConfigurationModal';
import Response from './Response';
import PromptInput from './PromptInput';
import HeaderActions from './HeaderActions';
import { useAuth } from '../AuthContext';
import { createChatHistory } from '../api/api';
import { encryptData } from '../utils/Security';
import { v4 as uuidv4 } from 'uuid';
import CircularProgress from '@mui/material/CircularProgress';

const ChatPrompt = ({ configurations, setConfigurations, models }) => {
  const [prompt, setPrompt] = useState('');
  const [responses, setResponses] = useState([]);
  const [error, setError] = useState('');
  const [timeLimit, setTimeLimit] = useState(60000);
  const [totalMsgCount, setTotalMsgCount] = useState(5);
  const [isProcessing, setIsProcessing] = useState(false);
  const [selectedModel, setSelectedModel] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { user } = useAuth();

  // Scroll the whole page when new responses are added
  useEffect(() => {
    window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
  }, [responses]);

  const getUUID = () => uuidv4().toString();

  const saveChatHistory = () => {
    const obj = {
      [prompt + '##' + new Date().toISOString()]: responses,
    };
    const key = 'aiOske5fS_2361c' + getUUID();
    const encryptedData = encryptData(JSON.stringify(obj));
    localStorage.setItem(key, encryptedData);

    if (user.premium) {
      createChatHistory({ content: encryptedData });
    }
  };

  useEffect(() => {
    const fetchTimeLimit = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/user-info`, {
          headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
        });
        setTimeLimit(response.data.timeLimit || 60000);
      } catch (error) {
        console.error('Error fetching time limit:', error);
        setTimeLimit(60000);
      }
    };
    fetchTimeLimit();
  }, []);

  const handleSubmit = async () => {
    if (!prompt.trim()) {
      setError('Please enter a prompt.');
      return;
    }

    if (Object.keys(configurations).length === 0) {
      setError('Please configure at least one model to begin chatting!');
      return;
    }

    setError('');
    setIsProcessing(true);

    try {
      let currentPrompt = prompt;
      let newResponses = [...responses];
      // alert('my token: '+localStorage.getItem('token'))

      for (let i = 0; i < Math.min(10, totalMsgCount); i++) {
        
        const response = await axios.post(`${process.env.REACT_APP_API_URL}/initiate`, { prompt: currentPrompt, configurations }, {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        });
        newResponses = [...newResponses, ...response.data.responses];
        setResponses(newResponses);

        const lastResponse = response.data.responses?.[0]?.response;
        if (!lastResponse) break;

        currentPrompt = lastResponse;
        await new Promise((resolve) => setTimeout(resolve, 7000));
      }
    } catch (err) {
      setError('Error processing the prompt.');
      console.error('Error:', err);
    } finally {
      setIsProcessing(false);
    }
  };

  const handleConfigure = (modelName) => {
    setSelectedModel(modelName);
    setIsModalOpen(true);
  };

  const handleSaveConfig = (modelName, config) => {
    setConfigurations((prev) => ({
      ...prev,
      [modelName]: config,
    }));
  };

  const handleClearChat = () => {
    setResponses([]);
  };

  const suggestedTopics = [
    'Healthcare in the United States',
    'Political Corruption: Insider Trading',
    'Billionaires in Government',
    'The Spread of Misinformation via Social Media',
    'Youth and Money Management',
    'Mental Health: Breaking the Stigma',
  ];

  return (
    <Container component="main" maxWidth="lg">
      <ConfigurationModal
        open={isModalOpen}
        modelName={selectedModel}
        config={configurations[selectedModel] || { tone: 'neutral', imitate: '', formality: 'medium' }}
        onClose={() => setIsModalOpen(false)}
        onSave={handleSaveConfig}
      />

      <HeaderActions handleClearChat={handleClearChat} saveChatHistory={saveChatHistory} />

      <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
        <ModelDrawer models={models} onConfigure={handleConfigure} configurations={configurations} />

        <PromptInput
          totalMsgCount={totalMsgCount}
          setTotalMsgCount={setTotalMsgCount}
          suggestedTopics={suggestedTopics}
          prompt={prompt}
          setPrompt={setPrompt}
          handleSubmit={handleSubmit}
          isProcessing={isProcessing}
          error={error}
        />

        <Box
          sx={{
            // mt: 3,
            // border: '1px solid #ddd',
            borderRadius: 2,
            // backgroundColor: '#fafafa',
            p: 2,
          }}
        >
          <Response responses={responses} />
       

        {isProcessing && (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <CircularProgress size={24} sx={{ color: 'primary' }} />
          </Box>
        )}
        </Box>
      </motion.div>
    </Container>
  );
};

export default ChatPrompt;
