import React from "react";
import { Typography, Box, Paper, Avatar, Grid } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import FaceIcon from "@mui/icons-material/Face";
import EmojiNatureIcon from "@mui/icons-material/EmojiNature";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import { deepPurple, teal, amber, lightBlue, orange } from "@mui/material/colors";

// Generate unique styles for each character
const characterStyles = {
  Alice: { bgColor: lightBlue[100], avatarColor: deepPurple[500], icon: <FaceIcon /> },
  Bob: { bgColor: amber[100], avatarColor: teal[500], icon: <EmojiNatureIcon /> },
  Charlie: { bgColor: orange[100], avatarColor: lightBlue[500], icon: <EmojiEmotionsIcon /> },
  Dave: { bgColor: teal[100], avatarColor: amber[500], icon: <EmojiPeopleIcon /> },
};

const getCharacterStyle = (character) => {
  const defaultStyle = { bgColor: "#f0f0f0", avatarColor: "#666", icon: <EmojiNatureIcon /> };
  return characterStyles[character] || defaultStyle;
};

const Response = ({ responses }) => {
  return (
    <Box sx={{ mt: 4, width: "100%", px: { xs: 2, sm: 4 } }}>
      <AnimatePresence>
        {responses.map((res, index) => {
          const { bgColor, avatarColor, icon } = getCharacterStyle(res.character);

          return (
            <motion.div
              key={index}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.5, ease: "easeOut" }}
              whileHover={{ scale: 1.02 }}
            >
              <Paper
                sx={{
                  p: 3,
                  mb: 3,
                  borderRadius: 4,
                  backgroundColor: bgColor,
                  boxShadow: "0 8px 20px rgba(0, 0, 0, 0.1)",
                  "&:hover": {
                    boxShadow: "0 12px 24px rgba(0, 0, 0, 0.2)",
                  },
                }}
              >
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Avatar
                      sx={{
                        bgcolor: avatarColor,
                        width: 56,
                        height: 56,
                        fontSize: "1.5rem",
                        color: "#fff",
                      }}
                    >
                      {icon}
                    </Avatar>
                  </Grid>
                  <Grid item xs>
                    <Typography
                      variant="h6"
                      sx={{
                        fontWeight: "bold",
                        color: "#333",
                        textTransform: "capitalize",
                      }}
                    >
                      {res.character}
                    </Typography>
                    <Typography
                      variant="subtitle2"
                      sx={{
                        color: res.model === "Premium" ? "#FFD700" : "#666",
                        fontStyle: res.model === "Premium" ? "italic" : "normal",
                      }}
                    >
                      {res.model} Model
                    </Typography>
                  </Grid>
                </Grid>
                <Box mt={2}>
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: "1rem",
                      color: "#555",
                      lineHeight: 1.6,
                      fontStyle: "italic",
                    }}
                  >
                    {res.response}
                  </Typography>
                </Box>
              </Paper>
            </motion.div>
          );
        })}
      </AnimatePresence>
    </Box>
  );
};

export default Response;
